<template>
  <div class="app-container">
    <div class="top" style="margin: 10px 0">
      <el-button type="success" icon="el-icon-plus" @click="showAdd"
        >新增</el-button
      >
      <el-button
        type="primary"
        :disabled="selectionList.length !== 1"
        icon="el-icon-edit-outline"
        @click="showEdit(selectionList[0])"
        >编辑</el-button
      >
      <!-- @click="" -->
      <el-button
        type="danger"
        :disabled="selectionList.length > 0 ? false : true"
        icon="el-icon-edit-outline"
        @click="handleDel"
        >删除</el-button
      >
    </div>
    <el-table
      :data="listData"
      style="width: 100%; margin-bottom: 20px; text-align: center"
      row-key="id"
      border
      default-expand-all
      @selection-change="handleSelectionChange"
      :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="no" label="编号"></el-table-column>
      <el-table-column prop="name" label="名称"></el-table-column>
      <el-table-column prop="measure" label="尺寸"></el-table-column>
      <el-table-column prop="location" label="位置"></el-table-column>
      <el-table-column prop="sourceType" label="类型">
        <template slot-scope="scope">
          <span>{{ getSourceType(scope.row.sourceType) }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="sourceContent" label="源内容"></el-table-column> -->
    </el-table>

    <el-dialog
      :title="handelType === 'new' ? '新增' : '编辑'"
      :visible.sync="dialogVisible"
      width="800px"
      center
      :close-on-click-modal="false"
    >
      <el-form
        ref="ruleForm"
        :model="form"
        label-width="120px"
        :inline="true"
        :rules="rules"
      >
        <!-- <el-form-item label="编号">
          <el-input v-model="form.no" />
        </el-form-item> -->
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item label="分辨率" prop="measure">
          <el-input v-model="form.measure" />
        </el-form-item>
        <el-form-item label="位置" prop="location">
          <el-input v-model="form.location" />
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="form.sourceType" style="width: 202px">
            <el-option
              v-for="(item, index) in typeList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="content" label="源内容" prop="sourceContent">
          <template v-if="form.sourceType == 2">
            <el-input v-model="form.sourceContent" />
          </template>
          <template v-else>
            <div class="upload-box">
              <div class="pic-list">
                <template v-if="form.sourceType == 0">
                  <div
                    class="list-box"
                    v-for="(item, index) in form.fileList"
                    :key="item"
                  >
                    <img :src="`api/${item}`" class="avatar" />
                    <span class="el-upload-list__item-actions">
                      <span class="el-upload-list__item-preview"
                        ><i
                          @click="previewImg(item)"
                          class="el-icon-zoom-in"
                        ></i>
                      </span>
                      <span class="el-upload-list__item-delete"
                        ><i
                          @click="imgDelete(index)"
                          class="el-icon-delete"
                        ></i> </span
                    ></span>
                  </div>
                </template>
                <template v-if="form.sourceType == 1">
                  <div
                    class="list-box"
                    v-for="(item, index) in form.fileList"
                    :key="item"
                  >
                    <video class="avatar" :src="`api/${item}`"></video>
                    <span class="el-upload-list__item-actions">
                      <span class="el-upload-list__item-preview"
                        ><i
                          @click="previewVideo(item)"
                          class="el-icon-caret-right"
                        ></i>
                      </span>
                      <span class="el-upload-list__item-delete"
                        ><i
                          @click="imgDelete(index)"
                          class="el-icon-delete"
                        ></i> </span
                    ></span>
                  </div>
                </template>
              </div>
              <el-upload
                class="avatar-uploader"
                action="api/file/upload"
                :headers="{ token: getToken }"
                v-model="form.pic"
                :show-file-list="false"
                :on-success="handleSuccess"
              >
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </template>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="handelType === 'new'"
          type="primary"
          @click="submitForm('ruleForm')"
          >确 定</el-button
        >
        <el-button v-else type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="imgDialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <el-dialog :visible.sync="videoDialogVisible" @closed="dialogVideoUrl = ''">
      <div class="text-center">
        <video width="300px" autoplay :src="`api/${dialogVideoUrl}`"></video>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/bigScreenMgr";
import { getToken } from "@/utils/auth";
export default {
  data() {
    return {
      rules: {
        name: [
          //商品验证
          { required: true, message: "请输入名称", trigger: "blur" },
        ],
        measure: [{ required: true, message: "请输入分辨率", trigger: "blur" }],
        location: [{ required: true, message: "请输入位置", trigger: "blur" }],

      },
      listData: [],
      handelType: "",
      dialogVisible: false,
      imgDialogVisible: false,
      dialogImageUrl: "",
      videoDialogVisible: false,
      dialogVideoUrl: "",
      selectionList: [],
      form: {
        no: "",
        name: "",
        measure: "",
        location: "",
        sourceType: 0,
        sourceContent: "",
        picList: [],
        fileList: [],
      },
      typeList: [
        {
          name: "图片",
          id: 0,
        },
        {
          name: "视频",
          id: 1,
        },
        {
          name: "网页",
          id: 2,
        },
      ],
      getToken: getToken(),
    };
  },
  created() {
    this.getDataList();
  },
  mounted() {
    // this.getArr();
  },
  methods: {
       submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if(this.handelType=='new'){
              this.handleAdd()
            }else{
              this.handleEdit()
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
    handleSuccess(file) {
      this.form.fileList.push(file.data.path);
      this.$forceUpdate();
    },
    async getDataList() {
      let result = await api.list();
      this.listData = result.data.data;
    },
    showEdit(obj) {
      this.handelType = "edit";
      this.form = obj;
      this.form.fileList = obj.sourceContent.split(",");
      this.dialogVisible = true;
    },

    imgDelete(index) {
      console.log("index", index);

      this.form.fileList.splice(index, 1);
      this.$forceUpdate();
      console.log(this.form.fileList);
    },
    previewImg(src) {
      this.imgDialogVisible = true;
      this.dialogImageUrl = src;
    },
    previewVideo(src) {
      this.videoDialogVisible = true;
      this.dialogVideoUrl = src;
    },
    showAdd() {
      this.handelType = "new";
      this.form = {
        no: "",
        name: "",
        measure: "",
        location: "",
        sourceType: 0,
        sourceContent: "",
        fileList: [],
      };
      this.dialogVisible = true;
    },
    getSourceType(sourceType) {
      let str = "";
      sourceType = parseInt(sourceType);
      switch (sourceType) {
        case 0:
          str = "图片";
          break;
        case 1:
          str = "视频";
          break;
        case 2:
          str = "网页";
          break;
      }
      return str;
    },
    handleSelectionChange(e) {
      this.selectionList = e;
      console.log("e", this.selectionList);
    },

    handleEdit() {
      let { form } = this;

      form.sourceContent =
        form.sourceType != 2 ? form.fileList.toString() : form.sourceContent;
      console.log("this.form", form);
      api.update(form).then((res) => {
        if (res.code == 200) {
          this.$message({ message: "更新成功", type: "success" });
          this.getDataList();
          this.dialogVisible = false;
        }
      });
    },
    async handleAdd() {
         let { form } = this;
          if (form.sourceType == 0 || form.sourceType == 1) {
            form.sourceContent = form.fileList.toString();
          }

          let result = await api.create(form);
          console.log("result", result);

          if (result.code == 200) {
            this.$message({ message: "新增成功", type: "success" });
            this.dialogVisible = false;
            this.getDataList();
          }
    },
    handleDel() {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // let arr = [];
        // arr.push(id);
        console.log("sedssd", this.selectionList);
        let ids = this.selectionList.map((x) => {
          return x.id;
        });
        api.remove({ ids }).then((res) => {
          if (res.code == 200) {
            this.$message({ message: "删除成功", type: "success" });
            this.getDataList();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item label:after {
  content: "";
  display: inline-block;
  width: 100%;
}
.el-form-item {
  margin-right: 40px;
}
.el-form-item.content {
  display: block;
}
.info-form label {
  font-weight: normal;
}
.el-form-item__label {
  text-align: justify;
  height: 50px;
}
.searchInput {
  width: 400px;
}
.upload_info {
  position: absolute;
  bottom: 2px;
  width: 100%;
  height: 30px;
  line-height: 30px;
  background: rgba($color: #000, $alpha: 0.2);
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dashed #d9d9d9;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
// .avatar-uploader {
//   border: 1px dashed #d9d9d9;
// }
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.upload-box {
  display: flex;
}
.pic-list {
  display: flex;
  img {
    margin-right: 10px;
  }
  .list-box {
    position: relative;
  }
}

.el-upload-list__item-actions {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 0;
  top: 0;
  cursor: default;
  text-align: center;
  color: #fff;
  opacity: 0;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s;
}

.el-upload-list__item-actions:after {
  display: inline-block;
  content: "";
  height: 100%;
  vertical-align: middle;
}

.el-upload-list__item-actions span {
  display: none;
  cursor: pointer;
}

.el-upload-list__item-actions span + span {
  margin-left: 15px;
}

.el-upload-list__item-actions .el-upload-list__item-delete {
  position: static;
  font-size: inherit;
  color: inherit;
}

.el-upload-list__item-actions:hover {
  opacity: 1;
}

.el-upload-list__item-actions:hover span {
  display: inline-block;
}
</style>
